var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticClass: "d-flex align-center flex-column justify-center" },
    [
      _c(
        "div",
        {
          staticClass:
            "cursor-pointer d-flex align-center justify-start full-width mb-4 mr-2",
        },
        [
          _c("mew-button", {
            attrs: { "btn-style": "transparent", title: _vm.backTxt },
            nativeOn: {
              click: function ($event) {
                return _vm.close.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c("span", { staticClass: "mew-heading-2" }, [_vm._v("Send Your NFT ")]),
      _c("img", {
        attrs: { height: "150", src: _vm.nft.image, alt: "nft image" },
        on: { error: _vm.onImgErr },
      }),
      _c("div", { staticClass: "mb-4 mt-2" }, [
        _vm._v(_vm._s(_vm._f("concatName")(_vm.nft.name))),
      ]),
      _c("module-address-book", { on: { setAddress: _vm.setAddress } }),
      !_vm.enoughFunds && _vm.showBalanceError
        ? _c(
            "span",
            { staticClass: "redPrimary--text px-6 py-0 py-sm-3 mb-3 mb-sm-0" },
            [
              _vm._v(
                "You do not have enough " +
                  _vm._s(_vm.currencyName) +
                  " to send. "
              ),
              _c(
                "a",
                {
                  staticClass: "link",
                  attrs: { target: "_blank" },
                  on: { click: _vm.openBuySell },
                },
                [_c("u", [_vm._v("Buy More " + _vm._s(_vm.currencyName))])]
              ),
            ]
          )
        : _vm._e(),
      _c("mew-button", {
        staticClass: "mt-1 mb-3",
        attrs: {
          "has-full-width": false,
          disabled: _vm.disabled || !_vm.enoughFunds,
          title: "Send",
          "btn-size": "large",
          "color-theme": "primary",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.send(_vm.nft)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }